.alert_error {
  background-color: $alert_bg;
  color: white;
  border-top: 1px solid $danger-alt;


  .icon_alert_error {
    color: $danger-alt;
  }
}

.icon_warning {
  color: $warning;
}

.alert_info {
  color: $info;
  background-color: $info_bg;
  border-top: 1px solid $info;


  .icon_alert_info {
    color: $info;
  }
}

.alert_success {
  color: $success;
  background-color: $success_bg;
  border-top: 1px solid $success;


  .icon_alert_success {
    color: $success;
  }
}

.alert {
  @apply border-t-4 rounded-b px-4 py-2 shadow-md;

  p {
    margin-top: 0.4rem;
    margin-bottom: 0;
  }

  svg {
    @apply fill-current h-6 w-6 mr-4
  }
}

