.dark-mode {
  background-color: $dark !important;
  color: $white;

  .page-item .page-link {
    color: $white;
  }

  .ng2-smart-page-link {
    line-height: 1.25 !important;
    min-height: 38px;
    margin: 0;
    align-content: center;
  }

  table {
    width: 100%;
  }

  @media (max-width: 991.98px) {
    #sidebar-overlay {
      z-index: 800 !important;
    }
  }

  .mat-tab-header-pagination {
    background: #666cff;
  }

  .mat-tab-header-pagination:hover {
    background: #464ce0;
  }

  .mat-tab-header-pagination-disabled {
    background: #6666786b;
  }

  .mat-tab-header-pagination-disabled:hover {
    background: #6666786b;
  }

  .mat-paginator-page-size-value {
    color: $white;
  }

  .mat-paginator-page-size-label, .mat-select-value, .mat-paginator-range-label,
  .mat-form-field-appearance-legacy .mat-form-field-label, svg.mat-paginator-icon {
    color: $white;
  }

  .ng2-smart-sort-link {
    color: #e5e7eb !important;
    text-transform: capitalize;
  }

  .example-container {
    height: 65vh;
    max-width: 100%;
    overflow: auto;
  }

  .mat-dialog-content {
    .example-container {
      height: 50vh;
    }
  }

  input, textarea {
    caret-color: white !important;
  }

  .mat-select-value .mat-select-value-text span {
    @apply text-base
  }

  #toast-container {
    z-index: 1000;
    @apply top-16 w-1/6 text-base;

    .ngx-toastr {
      width: auto;
    }

    .toast-success {
      background-color: rgba(114, 225, 40, 0.3) !important;
      font-weight: bold;
      color: $white;
      border: 1px solid $success;
    }

    .toast-error {
      background-color: rgb(220, 53, 69, 0.5) !important;
      font-weight: bold;
      color: $white;
      border: 1px solid $error;
    }
  }

  .dialog-popup {
    color: $white;

    .buttons-box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-top: 27px;

      .btn {
        margin: 0;
      }
    }
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0;
  }

  .mat-slide-toggle .mat-slide-toggle-thumb {
    background-color: $danger-alt;
  }

  .mat-slide-toggle .mat-slide-toggle-bar {
    background-color: rgba(183, 41, 41, 0.62);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $success-alt;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #51872e9e;
  }

  .show-hide-password {
    position: absolute;
    right: 37px;
    border: 0;
    background-color: transparent;
    z-index: 899;
    top: 6px;
  }

  .cdk-overlay-container {
    z-index: 999;
  }

  .mat-dialog-container {
    background-color: #30334EFF;
    width: 90vw;
  }

  a {
    @apply text-base leading-6;
    margin: 0 0 0.625rem;
    letter-spacing: 0.15px;
  }

  h2 {
    margin: 0 auto 0 0;
    line-height: 1.6;
    font-weight: 500;
    font-size: 1.25rem;
  }

  fa-icon {
    margin: 7px;
  }

  .pull-right {
    float: right;
  }

  .pull-left {
    float: left;
  }

  .container-box {
    background-color: #30334EFF;
    color: #EAEAFFDD;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    background-image: none;
    overflow: hidden;
    box-shadow: #1415212D 0 2px 10px 0;

    .content {
      padding: 1.5rem;
    }

    .head {
      display: flex;
      align-items: center;
      padding: 1.25rem;
      flex-direction: row;
      justify-content: space-between;

      h2 {
        line-height: 1.6;
        font-weight: 500;
        font-size: 1.25rem;
      }
    }
  }

  .main-footer {
    background-color: $dark !important;
    border-color: $dark !important;
  }

  .content-wrapper {
    background-color: $dark !important;
    color: $white;

    .content-header {
      background-color: $dark !important;
      color: $white;
    }
  }

  .bg-logo {
    background-color: #e9e9e9 !important;
  }

  .bg-info {
    background-color: #30334e !important;
    color: #EAEAFFDD !important;
  }

  .main-header, .main-sidebar {
    border-bottom: 0;
    z-index: 899 !important;
  }

  .sidebar-open {
    .children-menu {
      .nav-link {
        @apply w-auto ml-8 px-2 py-2
      }
    }
  }

  .sidebar-collapse {
    .nav-sidebar {
      overflow: hidden;
    }

    .children-menu {
      .nav-link {
        @apply w-auto ml-2 px-2;
        width: auto !important;
        padding-left: 0.65rem !important;
        padding-right: 0.65rem !important;

        p {
          display: none;
        }
      }
    }
  }

  .sidebar {
    height: 100% !important;
  }

  .mat-tab-label:hover {
    background-color: #ffffff17;
  }

  .mat-tab-label.mat-tab-label-active {
    color: $primary-active;
    border-bottom: 2px solid $primary-active;
    font-weight: bold;
    opacity: 1;
  }

  .mat-tab-label {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    color: #EAEAFFAD;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    flex-shrink: 0;
    padding: 12px 16px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    flex-direction: column;
    line-height: 1.5;
  }

  td table-cell-view-mode .ng-star-inserted {
    max-height: 31px !important;
    overflow: hidden;
    white-space: nowrap;
  }
}

.icon-user-topbar {
  @apply w-8 h-8;
  border-radius: 50%;
}

#user-menu {
  .nav-link {
    @apply p-0 pr-0 pl-0 flex mr-3.5
  }
}

.text-dark {
  color: $white !important;
}

.small-box {
  border-radius: 10px;
  background-image: none;
  overflow: hidden;
  box-shadow: rgb(20 21 33 / 18%) 0 2px 10px 0;
  position: relative;
  font-weight: normal;

  > .inner {
    padding: 1.25rem;
  }

  .icon {
    color: rgba(234, 234, 255, 15%);
  }
}
