.dropdown-menu {
  background-color: #30334EFF !important;
}

.img-circle {
  width: 50px;
}

.menu-dropdown-detail-user {
  border-bottom: 0.5px solid $grey;
}

.user-menu > .nav-link {
  cursor: pointer;
}

.user-menu > .nav-link:after {
  content: none;
}

.user-menu > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
  width: auto;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
  height: auto;
  text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
  z-index: 5;
  height: 45px;
  width: 45px;
  border: 3px solid rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
  z-index: 5;
  font-size: 15px;
  text-align: left;
  margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
  display: block;
  font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
  border-bottom: 1px solid #495057;
  border-top: 1px solid #dee2e6;
  padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
  display: block;
  clear: both;
  content: '';
}

@media (min-width: 576px) {
  .user-menu > .dropdown-menu > .user-body a {
    background: #ffffff !important;
    color: #495057 !important;
  }
}

.user-menu > .dropdown-menu > .user-footer {
  padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
  display: block;
  clear: both;
  content: '';
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
  color: #6c757d;
}

@media (min-width: 576px) {
  .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
    background-color: #f8f9fa;
  }
}

.user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 2.1rem;
  margin-right: 10px;
  margin-top: -2px;
  width: 2.1rem;
}

@media (min-width: 576px) {
  .user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-right: 0.4rem;
    margin-top: -8px;
  }
}

.user-menu .user-image {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0;
  margin-left: -8px;
}
