.btn {
  font-size: 0.8rem;
  @apply font-medium uppercase;

  :disabled {
    background-color: $disabled-bg !important;
    border-color: $disabled-bg !important;
    color: $disabled-cl !important;

    :hover {
      background-color: $disabled-bg !important;
      border-color: $disabled-bg !important;
      color: $disabled-cl !important;
    }
  }
}

.buttons-action-table {
  min-width: 40px !important;
}

.btn-primary:hover {
  background-color: $primary-hover;
  border-color: $primary-hover;
}

.btn-download {
  background-color: $warning-alt !important;
  border-color: $warning-alt !important;
}

.btn-download:hover {
  background-color: darken($warning-alt, 10%) !important;
  border-color: darken($warning-alt, 10%) !important;
}

.btn-error {
  background-color: $error !important;
  border-color: $error !important;
}

.btn-success {
  background-color: $success !important;
  border-color: $success !important;
}

.btn-default {
  background-color: $secondary-alt;
  border-color: $secondary-alt;
  color: white;

  :hover {
    background-color: $disabled-input-bg;
    border-color: $disabled-input-bg;
  }
}
