/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import '~ionicons-css/dist/icon.css';
@import "~flag-icon-css/css/flag-icons.min.css";

@import "app/styles/scss/utilities/custom-variables";
@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import "~admin-lte/build/scss/adminlte";
@import "ngx-toastr/toastr-bs4-alert";

//Custom styles
@import "app/styles/scss/custom-styles";
@import "app/styles/scss/components/ng2table";
@import "app/styles/scss/components/tables";
@import "app/styles/scss/components/button";
@import "app/styles/scss/components/form";
@import "app/styles/scss/components/alert";
@import "app/styles/scss/components/user";

//External Library
@import 'ag-grid-community/src/styles/ag-grid';
@import 'ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  ::-webkit-scrollbar {
    width: 15px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(154, 154, 154, 0.6);
    outline: 1px solid #151731;
  }

  body {
    font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  label {
    font-weight: normal !important;
  }

  input[type=radio], input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type=checkbox] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  input[type=checkbox] + label {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 20px;
  }

  input[type=checkbox] + label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    width: 17px;
    height: 17px;
    background-color: transparent;
    border: 2px solid $grey;
    border-radius: 2px;
    box-shadow: none;
    margin-right: 8px;
  }

  input[type=checkbox]:checked + label::after {
    content: " ";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZGUxZmNiIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: center center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    left: 0;
    top: 0;
    text-align: center;
    background-color: transparent;
    font-size: 10px;
    height: 20px;
    width: 20px;
  }

}

@layer components {
  .brand-image {
    border-radius: 10%;
  }

  .content {
    padding: 0 0.5rem;
  }

  // Sidebar menu animation
  // Needed for open/close menu animation for menus with child nodes
  .nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
  .nav .nav-item > .ng-trigger-openClose.ng-animating {
    overflow: hidden;
    display: block;
  }

  // Override original AdminLTE transform
  .nav-sidebar .menu-is-opening > .nav-link i.right, .nav-sidebar .menu-is-opening > .nav-link svg.right, .nav-sidebar .menu-open > .nav-link i.right, .nav-sidebar .menu-open > .nav-link svg.right {
    transform: none;
  }

  .main-header {
    overflow: inherit !important;
  }

  .main-sidebar {
    position: fixed !important;
  }
}


