$dark: #282A42;
$dark-main-bg: #282A42;
$sidebar-dark-bg: #282A42;
$white: #eaeaffde;
$primary-active: #666cff;
$primary-hover: #4c50cb;
$underline-color: white;
$border-color: #bfbfbf73;
$disabled-input-bg: rgba(255, 255, 255, 0.18);
$grey: #bfbfbf73;
$error: rgb(255, 77, 73);
$primary-alt: #666cff !default;
$secondary-alt: rgb(142, 68, 173);
$success-alt: rgb(81 135 45);
$danger-alt: rgb(224, 68, 64);
$alert_bg: rgb(224 68 64 / 17%);
$info_bg: rgb(38 198 249 / 17%);
$info: rgb(59, 203, 249);
$warning-alt: rgb(223, 159, 35);
$disabled-bg: #616376 !important;
$disabled-cl: rgba(234, 234, 255, 0.26);
$success_bg: rgb(128 228 61 / 17%);
$success: $success-alt;
$second_bg: rgb(48, 51, 78);
$bg-focus-inputs: rgba(154, 165, 238, 0.2);

$theme-colors-alt: () !default;
$theme-colors-alt: map-merge((
  "primary": $primary-alt,
  "secondary": $secondary-alt,
  "success": $success-alt,
  "info": $primary-alt,
  "warning": $warning-alt,
  "danger": $danger-alt,
  "dark": $primary-alt,
), $theme-colors-alt);

